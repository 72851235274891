import React from 'react'
import { ErrorMessage } from '@hookform/error-message';

const Input = ({ label, name, register, type, errors, onBlur = () => { },
    disabled, value, dirtyFields, className, labelClassName = "p-title" ,
    toggleVisibility, showPassword,isPasswordField = false, // האם השדה הוא שדה סיסמה
}) => {
  return (
    <span>
      <label className={labelClassName} htmlFor={name}>{label}</label>
      <div className="input-wrapper" style={{ position: "relative" }}>

        <input
          className={`${className} ${dirtyFields && dirtyFields[name] ? "dirtyFields" : ""}`}
          type={type}
          {...register(name, {
            onBlur: (e) => { onBlur(e) },
          })}

          id={name}
          disabled={disabled}
          value={value}
          style={isPasswordField ? { paddingRight: "40px" } : {}}
        />
        {isPasswordField && (
          <span
            onClick={toggleVisibility}
            className="password-icon"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          >
            {showPassword ? (
              <i className="fa fa-eye-slash"></i>
            ) : (
              <i className="fa fa-eye"></i>
            )}
          </span>
        )}
      </div>
      {errors ? (
        <div style={{ color: "red" }}>
          {errors[name] ? errors[name].message : null}
        </div>
      ) : null}
    </span>
  );
};

export default Input;




export const Select = React.forwardRef(({ name, label, options, errors, onChange, className }, ref) => (
    <div className={className}>
        <label htmlFor={name}>{label}</label>
        <select id={name} name={name} ref={ref} onChange={onChange ? onChange : null}>
            <option value="" disabled defaultValue="">בחר</option>
            {options ? options.map(x => <option key={x.Id} value={x.Id}>
                {x.Name}
            </option>) : null}
        </select>
        <ErrorMessage errors={errors} name={name} />
    </div>
));