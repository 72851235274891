import React, { useState } from "react";
import showAlert from "../../server/ShowAlert";
import { globalFileServer } from "../../../axiosInstance";
import { setPreload } from "../../server/CartsOpen";
import { useDispatch } from "react-redux";
import { UploadImage } from "../../server/UploadImage";
import {UploadImageData} from "../../server/UploadImage"
import MyCropperComp from "./MyCrooperComp";

const MyCropper = ({
  Mkt,
  IdImgGs1,
  itemId,
  folder,
  uploadImg,
  beforeFileName,
  NameId,
  Table,
  type,
}) => {
  const dispatch = useDispatch();
  const [cropped, setCropped] = useState(false);
  const [src, setSrc] = useState(null);
  const [srcPrev, setSrcPrev] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

const save = (event) => {
  if (event) {
      event.preventDefault(); 
  }

  if (!cropped) {
      showAlert({
          msgTitle: "שגיאה",
          msgText: "לא גזרת את התמונה.",
          msgType: "error",
      });
      return;
  }

  const fileName =
      new Date()
          .toLocaleTimeString("he-Il", {
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
          })
          .split(":")
          .join("") + (type ? type : ".jpg");

  dispatch(setPreload(true));

  const formData = new FormData();
  formData.append("Image", srcPrev); // כאן נשלחת התמונה הגזורה
  formData.append("FileName", beforeFileName + fileName);
  formData.append("ItemId", itemId);

  UploadImageData({
      selectedFile: srcPrev, // שינוי כאן כדי לשלוח את התמונה הגזורה
      FileName: beforeFileName + fileName,
      ItemId: itemId,
      Mkt: Mkt,
      IdImgGs1: IdImgGs1,
  })
      .then(() => {uploadImg({ FileName: beforeFileName + fileName, ImgData: srcPrev });})
       .catch(() => showAlert())
      .finally(() => cancel());
};

  const cancel = () => {
    setSrc(null);
    setSrcPrev(null);
    dispatch(setPreload(false));
  };

  const _onChange = (e) => {
    e.preventDefault();
    if (e.target.files.length) {
      const file = e.target.files[0];
  
      if (file.size > 9000000) {
        showAlert({
          msgTitle: "הקובץ חורג מהמשקל 8 mb",
          msgText: "יש לנסות להעלות קובץ שוב",
          msgType: "info",
        });
      } else {
        setSelectedFile(file); // שמירת הקובץ המקורי
        const reader = new FileReader();
        reader.onload = () => {
          setSrc(reader.result);
          setSrcPrev(reader.result);
          setCropped(true);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  
  
  return (
    <div className="load-image-wrapper">
      <div className="addImg-custom">
        <div className="plus">
          <img
            loading="lazy"
            src={`${globalFileServer}iconcss/plus-white.svg`}
            alt="icons plus-white"
          />
        </div>
        <img
          loading="lazy"
          src={`${globalFileServer}iconcss/placeholder.svg`}
          alt=" icons placeholder"
        />
        <input type="file" className="upload" onChange={_onChange} />
      </div>
      <MyCropperComp
        save={save}
        cancel={cancel}
        src={src}
        srcPrev={srcPrev}
        setSrcPrev={setSrcPrev}
        cropped={cropped}
        setCropped={setCropped}
      />
    </div>
  );
};

export default MyCropper;
