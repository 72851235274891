import React, { Fragment, useState } from 'react';
import { setUserEntry, customerRegister, PutCustomer, loginToken } from '../../server/Customer'
import { useDispatch, useSelector } from 'react-redux';
import { globalFileServer } from '../../../axiosInstance'
import { useForm } from 'react-hook-form';
import Input from "../containers/InputForm";
import { validPhoneNumber } from '../../ValidFunction';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { setPreload } from '../../server/CartsOpen'
import showAlert from '../../server/ShowAlert';

const UserEntry = ({ parentName }) => {
    const schema = yup.object({
        Email: yup.string().email("כתובת מייל").required("דואר אלקטרוני חובה"),
        Name: yup.string().required("שם חובה"),
        LastName: yup.string().required("שם חובה"),
        CustomerWebId: yup.string(),
        Phone2: yup.string().nullable().matches(validPhoneNumber, 'מספר טלפון לא תקין'),
        Phone: yup.string("מספרים בלבד").required('חובה להכניס מספר טלפון').matches(validPhoneNumber, 'מספר טלפון לא תקין'),
        password:parentName === 'signIn' ?  yup.string()
        .required('חובה להכניס סיסמה')
        .matches(/[a-zA-Z]/, "הסיסמה חייבת להכיל אותיות באנגלית ספרה או תו מיוחד")
        .matches(/[0-9.,-]/, "הסיסמה חייבת להכיל לפחות ספרה אחת או תו מיוחד")
        .min(8, "הסיסמה חייבת להיות לפחות 8 תווים")
        .max(15, "הסיסמה יכולה להיות עד 15 תווים בלבד"):null,
        confirmPass: parentName === 'signIn' ? yup.string().required("").oneOf([yup.ref('password'), null], 'סיסמה לא מתאימה') : null,
        InvoicingName: parentName === 'privateArea' || parentName === 'endShopping' ? yup.string() : null,
    }).required();

    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [invoicing, setInvoicing] = useState("MyName");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const { register, handleSubmit,  formState: { errors } } = useForm({
        defaultValues: { ...user },
        resolver: yupResolver(schema)
    });
    const preload = useSelector(state => state.openCart.preload)

    const send = (data) => {
        if (!preload) {
            dispatch(setPreload(true));
            if (parentName === 'signIn') {
                data.StationId = 1;
                data.Active = 1;
                if (data.Verifiad) {
                    dispatch(customerRegister(data))
                }
                else {
                    showAlert({ msgTitle: "אשר את התקנון"})
                    dispatch(setPreload(false));
                }
            }
            else {
                dispatch(PutCustomer(data));
                showAlert({ msgTitle:"הפרטים עודכנו בהצלחה", msgText: '', msgType: 'success'})
                setTimeout(() => window.scrollTo(0, 0), 200)
            }

        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
      const toggleShowConfirmPassVisibility = () => {
        setShowConfirmPass(!showConfirmPass);
      };
    return (
        <Fragment>
            <form className="register-form" onSubmit={handleSubmit(send)}>
                {parentName === 'signIn' ? <span className='note'>שדות חובה מסומנים ב*</span> : null}
                <div className='details-form'>
                    <div className='row-form'>
                        <Input
                            type="text"
                            register={register}
                            label={parentName == 'endShopping' ? "שם פרטי" : "שם פרטי*"}
                            disabled={parentName === 'endShopping' ? true : false}
                            errors={errors}
                            name="Name" />
                        <Input
                            type="text"
                            register={register}
                            label={parentName == 'endShopping' ? "שם משפחה" : "שם משפחה*"}
                            disabled={parentName === 'endShopping' ? true : false}
                            errors={errors}
                            name="LastName" />
                    </div>
                    <div className='row-form'>

                        <Input
                            type="number"
                            register={register}
                            label={parentName === 'signIn' ? "טלפון*" : "טלפון"}
                            disabled={parentName === 'signIn' ? false : true}
                            
                            errors={errors}
                            name="Phone" />
                        <Input
                            type="number"
                            register={register}
                            label="טלפון נוסף"
                            errors={errors}
                            disabled={parentName === 'endShopping' ? true : false}
                            name="Phone2" />
                            
                     </div>
                     <div className='row-form'>
                        <div className='input-container'> 
                             {parentName === 'signIn' ?
                                 <Input
                                 type={showPassword ? "text" : "password"}
                                 register={register}
                                 errors={errors}
                                 label="סיסמה*"
                                 name="password"
                                 className="password-input"
                                 toggleVisibility={togglePasswordVisibility}
                                 showPassword={showPassword}
                                 isPasswordField
                               />:null}
                        </div>
                           
                            {parentName === 'signIn' ?
                                 <Input
                                 type={showConfirmPass ? "text" : "password"}
                                 register={register}
                                 errors={errors}
                                 label="אימות סיסמה*"
                                 name="confirmPass"
                                 className="password-input"
                                 toggleVisibility={toggleShowConfirmPassVisibility}
                                 showPassword={showConfirmPass}
                                 isPasswordField
                               />:null}
                         
                    </div>
                    <div className='row-form'>

                        <Input
                            type="email"
                            register={register}
                            label={parentName === 'signIn' ? "דואר אלקטרוני*" : "דואר אלקטרוני"}
                            disabled={parentName === 'signIn' ? false : true}
                            
                            errors={errors}
                            name="Email"  />
                           <span>

                           </span>

                    </div>
                     <div className='row-form'>

                        {parentName === 'signIn' ?
                            <div className='checkbox-style'><Input type="checkbox" register={register} name="Verifiad" label={<span className='verify'> הנני מאשר/ת כי קראתי את <a target="_blank" href={globalFileServer + 'policy_form.pdf'}>תקנון </a>
                                אתר ׳המזון׳ ואת מדיניות הפרטיות, וברצוני להירשם בכפוף לתנאיהם.  </span>} /> </div> : null}
                        {parentName === 'signIn' ?
                            <div className='checkbox-style'><Input type="checkbox" register={register} name="VerifiadGetSms" label={<span className='verify'>הנני מאשר/ת לקבל הטבות, ועדכונים בדוא״ל וב-SMS
                                (ניתן להפסיק את קבלת העדכונים בכל עת).</span>} />
                            </div> : null}
                        {parentName == 'privateArea' || parentName == 'endShopping' ? <div className='invoicing-name'>
                            <p>חשבונית</p>
                            <div className='invoicing-radio-btn'>
                                <input type="radio" onChange={(e) => setInvoicing(e.target.value)} id="MyName" value="MyName" name="invoicing" checked={invoicing === "MyName"} />

                                <label className="MyName">על שמי</label>
                                <input type="radio" onChange={(e) => setInvoicing(e.target.value)} id="OtherName" value="OtherName" name="invoicing" checked={invoicing === "OtherName"} />

                                <label className="OtherName"> שם אחר</label>
                            </div>
                            {invoicing === "OtherName" ? <Input type="text" register={register} label="חשבונית על שם " errors={errors} name="InvoicingName" /> : null}
                        </div> : null}
                    </div>
                    <div className='row-form'>

                        {parentName === 'endShopping' ? null : <button className='register-btn' type='submit' >{parentName === 'signIn' ? `הרשמה >` : `עדכון`}</button>}
                        {parentName === 'signIn' ? <button className="cancel" onClick={() => dispatch(setUserEntry(false))}>כניסה ללא הרשמה&gt;</button> : null}

                    </div>
                </div>
            </form>

        </Fragment>

    )
}

export default UserEntry
