import React, { useState, useEffect } from 'react';
import showAlert from '../../../server/ShowAlert';
import { Payment } from '../../../server/Payment'
import { useParams } from 'react-router-dom';
import { GetPaymentPerOrder, GetCards } from '../../../server/Payment'
import PyaNedarim from './PayNedarim';
import { useDispatch } from 'react-redux';
import { setPreload } from '../../../server/CartsOpen'
import SelectNumPay from './PayNum';
import PopUp from '../../containers/PopUp';


const PayPopup = ({ closePayPopup, tranmode, SumPay, orderId }) => {
	const dispatch = useDispatch();
	const [sum, setSum] = useState(SumPay)
	const [massage, setMassage] = useState("המערכת מחפשת מידע מתאים");
	const [save, setSave] = useState(false);
	const [haveToAsk, setHaveToAsk] = useState(true);
	const [cardId, setCardId] = useState(null);
	const [Cards, setCards] = useState([]);
	const [ShowCards, setShowCards] = useState(true);
	const [numPaymentSelect, setNumPaymentsSelect] = useState(1);
	const [disabledClick, setDisabledClick] = useState(false)
	const { id } = useParams();

	const CalcNumberPayments = (totalPrice) => {
		if (totalPrice >= 400) {
			return (Math.floor(totalPrice / 200) < 4) ? Math.floor(totalPrice / 200) : 4
		}
		return 1;
	}


	const pay = () => {
		setDisabledClick(true)

		if (tranmode) {
			closePayPopup(true, cardId, numPaymentSelect);
		}
		else {
			const params = {
				Id: cardId,
				Sum: sum,
				cred_type: 1,
				OrderId: orderId,
				numPay: numPaymentSelect
			}
			Payment(params)
				.then(() => {
					dispatch(setPreload(false));
					closePayPopup(true);
				})
				.catch(() => {
					closePayPopup(false);
					showAlert({ msgTitle: 'העסקה נכשלה', msgText: 'אנא בדקו את פרטי הכרטיס או נסו כרטיס אחר', msgType: 'error' })
				});
		}

	}

	useEffect(() => {
		GetCards().then(x => setCards(x.data));
		if (id) {
			orderId = id.substring(5, id.length - 5);
			GetPaymentPerOrder(orderId)
				.then(x => {
					setSum(x.data.sum)
					setMassage("");
				})
				.catch(err => setMassage(err.Message));
		}
		else {
			setMassage("");
		}
	}, [])

	const total = Math.abs(parseFloat(sum).toFixed(2));
	const numPayments = CalcNumberPayments(total)
	const msg = " הסכום לתשלום הוא: "

	return (
		<PopUp width='800px' close={() => closePayPopup(false)}>
			<div className="pay-popup">
				<div className='sum-to-pay'>{tranmode ? msg + total + " ₪" : null}</div>

				<p className='massage'>{massage}</p>

				{numPayments > 1 ? <p className='note'> שימו לב: לא ניתן לשלם תשלומים בכרטיס דיירקט!</p> : null}
				{ShowCards && Cards.length ?
					<div className='use-saved-card'>
						ניתן לבחור כרטיס  ששמור במערכת
						{Cards.map(x => <div key={x.Id}>
							<input
								type="checkbox"
								checked={cardId === x.Id}
								onChange={() => setCardId(x.Id)}
							/>
							{x.Name}
						</div>)}
						{cardId ?
							<div>
								{numPayments ? <SelectNumPay numPayment={numPaymentSelect} setNumPayment={setNumPaymentsSelect} numPayments={numPayments} /> : null}
								{!numPayments || numPaymentSelect ? <button className="btn" disabled={disabledClick} onClick={pay}>אישור תשלום </button> : null}
							</div>
							: null}
						<button className="btn" onClick={() => setShowCards(false)}> ברצוני להשתמש בכרטיס אחר</button>
					</div> :
					<span className='save-card'>
						{tranmode && numPayments ? <SelectNumPay
							disabled={!haveToAsk}
							numPayment={numPaymentSelect}
							setNumPayment={setNumPaymentsSelect}
							numPayments={numPayments} /> : null}
						<p >
							האם ברצונך לשמור את פרטי האשראי במערכת להזמנות הבאות?
						</p>
						<span className='save-card-checkbox'>
							<div className='checkbox-style'>
								<label> כן </label>
								<input
									type="checkbox"
									disabled={!haveToAsk}
									onChange={() => { setSave(true); setHaveToAsk(false) }}
								/>

							</div>
							<div className='checkbox-style'>
								<label> לא </label>
								<input
									type="checkbox"
									disabled={!haveToAsk}
									onChange={() => { setSave(false); setHaveToAsk(false) }}
								/>

							</div>
						</span>
						<br />
					</span>


				}
				{!haveToAsk ?
					<PyaNedarim
						numPayments={numPayments}
						numPaymentSelect={numPaymentSelect}
						orderId={orderId}
						total={sum}
						save={save}
						tranmode={tranmode}
						ClosePayPopup={closePayPopup} /> : null}
			</div>
		</PopUp>

	);
}

export default PayPopup;